<template>
  <div>
    <b-card>
      <div class="pull-right mb-2">
        <b-button variant="info" @click="showMaskingTables">마스킹 테이블 보기</b-button>
        <b-button class="ml-1" variant="outline-secondary" v-b-modal.query>유용한 쿼리</b-button>
      </div>
      <b-modal id="query" size="xl" title="유용한 쿼리" ok-only>
        <b-tabs>
          <b-tab title="MariaDB 컬럼 찾기">
            <pre ref="query1">
SELECT *
  FROM information_schema.columns
 WHERE table_schema = 'godomall'
#   AND column_name = 'ordno'
#   AND table_name like '%order%'
 ORDER BY table_name, column_name
;
            </pre>
            <b-button @click="$utils.copyAlert($refs.query1.innerText.trim())">복사</b-button>
          </b-tab>
        </b-tabs>
      </b-modal>
      <b-modal v-model="modal.masking" size="xl" title="마스킹 테이블" ok-only>
        <pre>{{ maskingTables }}</pre>
      </b-modal>

      <h4>
        데이터베이스 테이블 및 컬럼 설명
        <b-spinner class="mb-1" variant="primary" small v-if="busy.meta"></b-spinner>
      </h4>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="search" :disabled="busy.search">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="mr-1" small v-if="busy.search"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input id="search" type="text" placeholder="" v-model="form.search" @keypress.enter="search" autocomplete="off" :disabled="busy.search" v-focus></b-form-input>
      </b-input-group>
      <div v-if="searchResult">
        <div v-if="searchResult.db.length">
          <h5><b-badge variant="success">DB 검색결과</b-badge></h5>
          <div v-for="db in searchResult.db">
            <b-badge variant="secondary" class="ml-2 pointer" @click="load(db.group, db.server, db.db)">{{db.group}}.{{db.server}}.{{db.db}}</b-badge> {{db.desc}}
          </div>
        </div>
        <div v-else-if="form.searched">
          <h5><b-badge variant="success">DB 검색결과가 없습니다</b-badge></h5>
        </div>
        <div v-if="searchResult.table.length">
          <h5><b-badge variant="info">Table 검색결과</b-badge><br/></h5>
          <div v-for="t in searchResult.table">
            <b-badge variant="secondary" class="ml-2 pointer" @click="load(t.group, t.server, t.db, t.table)">{{t.group}}.{{t.server}}.{{t.db}}.{{t.table}}</b-badge> {{t.desc}}
            <div v-for="c in t.columns.filter(e => e.name && e.name.match(form.searched) || e.desc && e.desc.match(form.searched) || e.link && e.link.match(form.searched))">
              <b-badge variant="light" class="ml-4 pointer" @click="load(t.group, t.server, t.db, t.table)">.{{c.name}}</b-badge>
              <span v-if="c.link" class="ml-1 italic text-info">[= {{c.link}}]</span> {{c.desc || ''}}
            </div>
          </div>
        </div>
        <div v-else-if="form.searched">
          <h5><b-badge variant="info">Table 검색결과가 없습니다</b-badge><br/></h5>
        </div>
      </div>
      <hr/>
      <b-row>
        <b-col xl="6">
          <b-row>
            <b-col class="border-right" lg="4">
              <h5>DB Servers</h5>
              <b-badge variant="light" class="mb-2">MongoDB</b-badge><br/>
              <b-button v-for="s in db.mongo" class="mb-1 mr-1" size="sm" variant="primary" @click="loadDb(s)">{{s.name}}</b-button><br/>

              <b-badge variant="light" class="my-2">MariaDB</b-badge><br/>
              <b-button v-for="s in db.mysql" class="mb-1 mr-1" size="sm" variant="success" @click="loadDb(s)">{{s.name}}</b-button><br/>

              <b-badge variant="light" class="my-2">Redis</b-badge><br/>
              <b-button v-for="s in db.redis" class="mb-1 mr-1" size="sm" variant="warning" @click="loadDb(s)">{{s.name}}</b-button><br/>
            </b-col>
            <b-col class="mt-2 mt-lg-0" lg="8">
              <h5>Databases</h5>
              <perfect-scrollbar style="max-height:300px;overflow-y:auto" v-if="dbs.length">
                <b-button v-for="d in dbs" class="mb-1 mr-1" size="sm" :variant="(d.desc ? '' : 'outline-') + (d.tables && d.tables.length === 0 ? 'secondary' : 'success')" @click="loadTable(d)">
                  {{d.name}}{{d.tables && d.tables.length ? ` (${d.tables.length})` : ''}}
                </b-button><br/>
              </perfect-scrollbar>
              <div v-else>
                DB Server 를 선택해주세요
              </div>
            </b-col>
            <b-col class="mt-2" cols="12">
              <div class="clearfix">
                <b-form class="pull-right mb-1" inline>
                  <b-form-checkbox v-model="maskingTable" class="mr-1">Masking</b-form-checkbox>
                  <b-form-input size="sm" placeholder="filter (regexp)" v-model="tableFilter"></b-form-input>
                </b-form>
                <h5>Tables(Collections)</h5>
              </div>
              <perfect-scrollbar style="max-height:300px;overflow-y:auto" v-if="tables.length">
                <b-button v-for="t in tables.filter(e => (!tableFilter || e.name.match(tableFilter)) && (!maskingTable || e.doc && e.doc.hasMasking))"
                          :key="t.name"
                          class="mb-1 mr-1" size="sm"
                          :variant="(t.doc ? '' : 'outline-') + 'info'"
                          @click="loadDocument(t)">
                  {{t.name}}{{t.TABLE_ROWS ? ` (${t.TABLE_ROWS})` : ''}} {{t.doc && t.doc.hasMasking ? '*' : ''}}
                </b-button><br/>
              </perfect-scrollbar>
              <div v-else>
                Database 를 선택해주세요
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="6">
          <hr class="d-xl-none" />
          <h4 v-if="IS_LOCAL">
            Server:
            <template v-if="dbObj.server">
              <b-badge variant="success">{{dbObj.group}}.{{dbObj.server}}</b-badge>
              <b-badge class="ml-1 pointer" variant="secondary" @click="downXlsxModal('server', dbObj)"
                       title="샘플 통계를 포함한 해당 DB 서버의 전체 DB 와 전체 테이블 다운로드(상당시간소요)">
                <b-spinner v-if="busy.xlsx" small></b-spinner>
                <i v-else class="fa fa-download"></i>
              </b-badge>
            </template>
          </h4>
          <h4>
            Database:
            <template v-if="dbObj.db">
              <b-badge variant="success">{{dbObj.group}}.{{dbObj.server}}.{{dbObj.db}}</b-badge>
              <b-badge class="ml-1 pointer" variant="secondary" @click="copyLink('db', dbObj)"><i class="fa fa-copy" title="hub 의 조회 링크 복사"></i></b-badge>
              <b-badge class="ml-1 pointer" variant="secondary" @click="downXlsxModal('db', dbObj)" title="샘플 통계를 포함한 해당 DB 의 전체 테이블 다운로드(상당시간소요)">
                <b-spinner v-if="busy.xlsx" small></b-spinner>
                <i v-else class="fa fa-download"></i>
              </b-badge>
            </template>
          </h4>
          설명:
          <b-textarea v-model="dbObj.desc" rows="5" :disabled="!dbObj.db"></b-textarea>
          <b-button class="mt-1" size="sm" variant="primary" @click="updateDb()" :disabled="!dbObj.db">저장</b-button>

          <h5 class="mt-3">
            Table:
            <template v-if="doc.name">
              <b-badge variant="info">{{doc.name}}</b-badge>
              <b-badge class="ml-1 pointer" variant="secondary" @click="copyLink('table', doc)"><i class="fa fa-copy" title="hub 의 조회 링크 복사"></i></b-badge>
              <b-badge class="ml-1 pointer" variant="secondary" @click="downXlsxModal('table', doc)" title="샘플 통계를 포함한 테이블 데이터 다운로드">
                <b-spinner v-if="busy.xlsx" small></b-spinner>
                <i v-else class="fa fa-download"></i>
              </b-badge>
              <b-badge class="ml-1 pointer" variant="secondary" @click="copyTable(doc)" title="해당 테이블을 복제">
                <b-spinner v-if="busy.copyTable" small></b-spinner>
                <i v-else class="fa fa-database"></i>
              </b-badge>
              <b-badge v-if="IS_DEV" class="ml-1 pointer" variant="secondary" @click="maskTable(doc)"><i class="fa fa-asterisk" title="마스킹 처리(DEV)"></i></b-badge>
            </template>
          </h5>

          <div>관련 시스템 등의 TAGS:</div>
          <v-select v-model="doc.tags" multiple taggable push-tags placeholder="HUB, GODO 등의 TAG 를 입력해주세요" :disabled="!doc.name">
            <template v-slot:no-options="{ search, searching }">
              <em style="opacity: 0.5;">관련 TAG 를 입력해주세요.</em>
            </template>
          </v-select>

          <div class="mt-2">참고링크:</div>
          <v-select v-model="doc.links" multiple taggable push-tags placeholder="관련 link 를 입력해주세요." :disabled="!doc.name">
            <template v-slot:no-options="{ search, searching }">
              <em style="opacity: 0.5;">관련 link 를 입력해주세요.</em>
            </template>
          </v-select>
          <div>
            <a v-for="e in doc.links" :href="e" target="_blank"><b-badge variant="light">{{e}}</b-badge></a>
          </div>

          <div class="mt-2">설명:</div>
          <b-textarea v-model="doc.desc" rows="6" :disabled="!doc.name"></b-textarea>
          <b-button class="mt-1" size="sm" variant="primary" @click="updateDocument()" :disabled="!doc.name">저장</b-button>
        </b-col>
      </b-row>

      <hr/>

      <b-alert class="mt-3" variant="success" show>Grid 의 * 컬럼은 MariaDB 에만 해당됩니다.</b-alert>
      <b-alert class="mt-3" variant="info" show>마스킹 유형은 개인정보의 마스킹을 위해 필요하며
        <a href="https://github.com/balaan-team1/general_utils/blob/main/masker.js#L4" target="_blank">링크</a>를 참고해서 입력해주세요</b-alert>

      <div class="clearfix">
        <div class="pull-right mb-1">
<!--      TODO:    Column 순서 컬럼명순, 스키마순 -->
<!--          <b-form-input size="sm" placeholder="filter (regexp)"></b-form-input>-->
        </div>
        <b-form inline>
          <b-button variant="secondary" size="sm" @click.prevent="loadSample(doc)" :disabled="!doc.name">샘플 Refresh</b-button>
          <b-input-group size="sm" class="ml-2 w-400px">
            <b-form-input v-model="sampleQuery" placeholder="goodsno = 1234567" @keypress.enter.prevent="loadSample(doc, sampleQuery)"></b-form-input>
            <b-input-group-append>
              <b-button variant="secondary" size="sm" @click.prevent="loadSample(doc, sampleQuery)" :disabled="!doc.name">샘플 쿼리</b-button>
            </b-input-group-append>
            <b-input-group-append>
              <b-button variant="secondary" size="sm" @click.prevent="$utils.alert(`a = 'a' and b in (1, 2) 등의 where 절 또는 JSON 형식을 입력해주세요`)">
                <i class="ml-1 fa fa-question-circle-o"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form>
      </div>

      <b-input-group class="mt-2">
        <b-form-input v-model="currentField" placeholder="하단 Grid 에서 선택된 값" readonly></b-form-input>
        <b-input-group-append>
          <b-button :variant="toggle.json ? 'light' : 'success'" @click="toggleJson()">JSON</b-button>
        </b-input-group-append>
      </b-input-group>
      <pre v-if="toggle.json" style="max-height:500px;overflow-y:auto">{{currentFieldJson}}</pre>
      <hot-table class="" ref="ht" :settings="hotSetting"></hot-table>
      <b-button class="mt-1" size="sm" variant="primary" @click="updateDocument()" :disabled="!doc.name">저장</b-button>

    </b-card>

    <b-modal v-model="modal.down" size="md" title="Xlsx 다운로드" ok-title="다운로드" cancel-title="닫기" @ok="downXlsx()">
      <div>Sheet Mode</div>
      <b-form-radio-group v-model="mergeSheet" :options="[{text: '하나의 Sheet 에 전체 테이블 통합', value: true}, {text: 'Table 별로 분리', value: false}]">
      </b-form-radio-group>
    </b-modal>

    <b-modal v-model="modal.copyTable" size="lg" title="테이블 복제" ok-title="복제시작" cancel-title="닫기" @ok="copyTableStart()">
      <div>복제할 위치를 입력해주세요</div>
      <b-form-input v-model="copyTableDst" placeholder="ex: db_dev.test.sample"></b-form-input>
      <div class="mt-2">복제할 조건을 지정해주세요</div>
      <template v-if="copyTableSrc.group === 'mongo'">
        <b-form-checkbox v-model="copyTableOptions.overwrite">collection 을 교체하지 않고 데이터를 overwrite 합니다(정합성에 주의해주세요)</b-form-checkbox>
        <b-form-checkbox v-model="copyTableOptions.setIdRange">_id 의 기간을 지정합니다</b-form-checkbox>
        <template v-if="copyTableOptions.setIdRange">
          <date-from-to :from.sync="copyTableOptions.dateFrom" :to.sync="copyTableOptions.dateTo" v-bind="{init: '3 month', absMonth: 12, year: 5}">
          </date-from-to>
        </template>
        <b-form-checkbox v-model="copyTableOptions.useQuery">쿼리로 조건을 지정합니다(JSON)</b-form-checkbox>
        <template v-if="copyTableOptions.useQuery">
          <b-textarea v-model="copyTableOptions.query" rows="5"></b-textarea>
        </template>
        <b-form-checkbox v-model="copyTableOptions.masking">지정된 Masking Rule 을 사용하여 마스킹 처리합니다.</b-form-checkbox>
      </template>
      <template v-else-if="copyTableSrc.group === 'mysql'">
        <b-form-checkbox v-model="copyTableOptions.useQuery">쿼리로 조건을 지정합니다</b-form-checkbox>
        <template v-if="copyTableOptions.useQuery">
          <b-textarea v-model="copyTableOptions.query" rows="5"></b-textarea>
        </template>
        <b-form-checkbox v-model="copyTableOptions.masking">지정된 Masking Rule 을 사용하여 마스킹 처리합니다.</b-form-checkbox>
      </template>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="danger" @click="ok()">
          복제시작
        </b-button>
        <b-button variant="info" @click="copyTableCount()">
          갯수확인
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>

    <iframe ref="file_frame" name="file_frame" style="width:1px;height:1px;visibility:hidden" @load="frameLoaded"></iframe>
    <form :action="$api.getHost() + '/data/store/xlsx'" ref="file_form" method="POST" target="file_frame" style="width:1px;height:1px;visibility:hidden">
      <input ref="json_data" type="hidden" name="j" />
    </form>
  </div>
</template>

<script>
import { HotTable } from '@handsontable/vue';

export default {
  name: 'TableWiki',
  title: 'DB 테이블 및 컬럼',
  components: {HotTable},
  data() {
    return {
      form: {search: '', searched: ''},
      searchResult: null,
      db: {
        mysql: [
          {name: '고도몰', group: 'mysql', server: 'godo'},
          {name: '통계', group: 'mysql', server: 'stat'},
        ],
        mongo: [
          {name: 'HUB DB', group: 'mongo', server: 'db'},
          {name: 'Second DB', group: 'mongo', server: 'second'},
          {name: 'Stat DB', group: 'mongo', server: 'stat'},
          {name: 'Src DB', group: 'mongo', server: 'src'},
          {name: 'Log DB', group: 'mongo', server: 'log'},
        ],
        redis: [
          {name: 'balaan-redis', group: 'redis', server: ''},
          {name: 'balaan-redis-sesesion', group: 'redis', server: ''},
          {name: 'proxy-hub', group: 'redis', server: ''},
          {name: 'node', group: 'redis', server: ''},
          {name: 'stat', group: 'redis', server: ''},
          {name: 'godo', group: 'redis', server: ''},
        ],
      },
      servers: [],
      dbs: [],
      tables: [],
      tableFilter: '',
      maskingTable: false,
      dbObj: {desc: ''},
      doc: {desc: ''},
      columns: [],
      currentField: '',
      currentFieldJson: '',
      sampleQuery: '',
      preset: {
        _id: {type: 'object_id', desc: 'MongoDB ObjectID'},
        _t: {type: 'number', desc: '마지막 작업시각(ms)'},
        _d: {type: 'string', desc: '마지막 작업일자'},
        _dt: {type: 'string', desc: '마지막 작업시각'},
        _at: {type: 'string', desc: '마지막 작업위치'},
        _uid: {type: 'string', desc: '마지막 작업 ID'},
        _ip: {type: 'string', desc: '마지막 작업 IP'},
        _name: {type: 'string', desc: '마지막 작업자'},
        _ct: {type: 'number', desc: '최초 생성시각(ms)'},
        _cdt: {type: 'string', desc: '최초 생성시각'},
        _cat: {type: 'string', desc: '최초 생성위치'},
        _cuid: {type: 'string', desc: '최초 생성 ID'},
        _cname: {type: 'string', desc: '최초 생성자'},
        _mt: {type: 'number', desc: '마지막 변경시각(ms)'},
        _mdt: {type: 'string', desc: '마지막 변경시각'},
        _mat: {type: 'string', desc: '마지막 변경위치'},
        _muid: {type: 'string', desc: '마지막 변경 ID'},
        _mname: {type: 'string', desc: '마지막 변경자'},
        _del_t: {type: 'number', desc: '삭제시각(ms)'},
        _del_dt: {type: 'string', desc: '삭제시각'},
        _del_at: {type: 'string', desc: '삭제위치'},
        _del_uid: {type: 'string', desc: '삭제 ID'},
        _del_name: {type: 'string', desc: '삭제자'},
        _del_reason: {type: 'string', desc: '삭제 사유'},
        _diff: {type: 'array', desc: '변경이력'},
        _diff_history: {type: 'array', desc: '변경체크시각 history'},
        _old_id: {type: 'object_id', desc: '삭제된 문서의 원본 ObjectID'},
        _server_ip: {type: 'string', desc: '작업이 일어난 서버의 네트워크 IP'},
        _set: {type: 'object', desc: '특정 필드의 반복적 변경이력'},
        _snap: {type: 'object', desc: '특정 필드의 변경 전 최초값 모음'},
      },
      fields: {
        columns: [
          {data: 'name', name: '컬럼명', width: 160, className: 'truncate htCenter', readOnly: true},
          {data: 'type', name: '타입', width: 100, renderer: (instance, td, row, col, prop, value, cellProperties) => {
              const link_type = this.$refs.ht.hotInstance.getDataAtRowProp(row, 'link_type'); // [name, type, desc, ...]
              if (td) {
                if (!value) { // 기존값이 없다면 회색 이탈릭 처리
                  td.className = 'htCenter htMiddle font-italic htDimmed';
                  td.innerText = link_type || '';
                } else {
                  td.className = 'htCenter htMiddle';
                  td.innerText = value;
                }
              }
            }},
          {data: 'desc', name: '설명', width: 300, renderer: (instance, td, row, col, prop, value, cellProperties) => {
              const link_desc = this.$refs.ht.hotInstance.getDataAtRowProp(row, 'link_desc'); // [name, type, desc, ...]
              // if (link_desc) console.log(link_desc, typeof link_desc, this.$refs.ht.hotInstance);
              if (td) {
                if (!value) { // 기존값이 없다면 회색 이탈릭 처리
                  td.className = 'htCenter htMiddle font-italic htDimmed';
                  td.innerText = link_desc || '';
                } else {
                  td.className = 'htCenter htMiddle';
                  td.innerText = value;
                }
              }
            }},
          {data: 'mask', name: '마스킹 유형', width: 100, renderer: (instance, td, row, col, prop, value, cellProperties) => {
              const link_mask = this.$refs.ht.hotInstance.getDataAtRowProp(row, 'link_mask'); // [name, type, desc, ...]
              if (td) {
                if (!value) { // 기존값이 없다면 회색 이탈릭 처리
                  td.className = 'htCenter htMiddle font-italic htDimmed';
                  td.innerText = link_mask || '';
                } else {
                  td.className = 'htCenter htMiddle';
                  td.innerText = value;
                }
              }
            }},
          {data: 'link', name: '원본링크', width: 280},
          {data: 'sample', name: '샘플데이터', width: 300, className: 'truncate htCenter', readOnly: true},
          {data: 'sample_type', name: '샘플타입', width: 100, readOnly: true},
          {data: 'COLUMN_COMMENT', name: '*Comment', width: 200, className: 'truncate htCenter', readOnly: true},
          {data: 'COLUMN_TYPE', name: '*Type', width: 120, className: 'truncate htCenter', readOnly: true},
          {data: 'COLUMN_DEFAULT', name: '*Default', width: 120, className: 'truncate htCenter', readOnly: true},
        ],
      },
      busy: {search: false, meta: false, doc: false, xlsx: false, copyTable: false},
      toggle: {json: false},
      modal: {down: false, copyTable: false, masking: false},
      maskingTables: '',

      hotSetting: {
        data: [],
        colHeaders: (index) => {
          return this.fields.columns[index].name;
        },
        columns: [],
        cells: (row, col, prop) => {
          let className = this.fields.columns[col].className;
          return className ? {className} : {};
        },
        className: "htCenter htMiddle",
        colWidths: [],
        autoWrapCol: false,
        autoWrapRow: false,
        manualColumnResize: true,
        columnSorting: true,
        height: 800,
        afterChange: (changes) => {
          // console.log(changes);
          if (changes && changes.some(row => ['type', 'desc', 'link', 'mask'].includes(row[1]) && row[2] !== row[3])) this.updateDocument(); // 수정 가능한 컬럼에 변화가 있을 때
        },
        afterSelection: (row, column, row2, column2, selectionLayerLevel, a, b) => {
          this.currentField = this.$refs.ht.hotInstance.getDataAtCell(row, column);
          if (!this.currentField) { // link 값으로 채워넣을지 판단.
            const name = this.$refs.ht.hotInstance.getCellMetaAtRow(row)[column].prop; // field 값 (ex: type)
            if (['type', 'desc', 'mask'].includes(name)) {
              this.currentField = this.$refs.ht.hotInstance.getDataAtRowProp(row, `link_${name}`);
            }
          }
          // this.$refs.ht.hotInstance.getDataAtRowProp(row, 'link_desc'); // [name, type, desc, ...]
          if (this.toggle.json) {
            this.toggle.json = false;
            this.toggleJson();
          }
        },
        licenseKey: 'non-commercial-and-evaluation',
      },
      downXlsxParams: {},
      mergeSheet: true,
      copyTableSrc: {},
      copyTableDst: '',
      copyTableOptions: {
        overwrite: false,
        setIdRange: false,
        dateFrom: '',
        dateTo: '',
        useQuery: false,
        query: '',
        masking: true,
      },
      frameCallback: () => {},
    }
  },
  async created() {
    this.hotSetting.columns = this.fields.columns;
    this.hotSetting.colWidths = this.fields.columns.map(e => e.width);

    const {search, group, server, db, table} = this.$route.query;
    if (group && server) {
      this.load(group, server, db, table);
    }
    if (search) {
      this.form.search = search;
      this.search();
    }
  },
  beforeDestroy() {
  },
  sockets: {
    tableWikiXlsxDone() {
      this.busy.xlsx = false;
    },
  },
  methods: {
    async load(group, server, db, table) {
      const serverObj = this.db[group].find(e => e.server === server);
      serverObj && await this.loadDb(serverObj);
      if (db) {
        const dbObj = this.dbs.find(e => e.group === group && e.server === server && e.db === db);
        dbObj && await this.loadTable(dbObj);
        if (table) {
          const tableObj = this.tables.find(e => e.group === group && e.server === server && e.db === db && e.table === table);
          tableObj && await this.loadDocument(tableObj);
        }
      }
    },
    async search() {
      this.busy.search = true;
      let j = await this.$api.postJson('/dev/tableWiki/search', {search: this.form.search});
      this.form.searched = this.form.search;
      this.busy.search = false;
      if (j) {
        this.searchResult = j;
      }
    },
    async loadDb(serverObj) {
      this.busy.meta = true;
      let j = await this.$api.postJson('/dev/tableWiki/getMeta', {type: 'db', query: serverObj});
      this.busy.meta = false;
      if (j) {
        this.dbs = j.list.map(e => ({...serverObj, ...e})).sort((a, b) =>
          a.desc && !b.desc ? -1 :
            !a.desc && b.desc ? 1 :
              a.name.localeCompare(b.name)
        );
        this.dbObj = {group: serverObj.group, server: serverObj.server};
        this.tables = [];
        this.doc = {};
        this.currentField = '';
        this.toggle.json = false;
        this.$refs.ht.hotInstance.loadData([]);
      }
    },
    async loadTable(dbObj) {
      this.busy.meta = true;
      let j = await this.$api.postJson('/dev/tableWiki/getMeta', {type: 'table', query: dbObj});
      this.busy.meta = false;

      // dbObj 가 다르다면 tableFilter 해제
      if (!this.dbObj || this.dbObj.group !== dbObj.group || this.dbObj.server !== dbObj.server || this.dbObj.db !== dbObj.db) {
        this.tableFilter = '';
      }

      this.dbObj = dbObj;
      if (j) {
        this.tables = j.list.map(e => ({...dbObj, ...e})).sort((a, b) =>
          a.doc && !b.doc ? -1 :
            !a.doc && b.doc ? 1 :
              a.name.localeCompare(b.name)
        );
        this.doc = {};
        this.currentField = '';
        this.toggle.json = false;
        this.$refs.ht.hotInstance.loadData([]);
      }
    },
    async loadDocument(tableObj) {
      this.busy.meta = true;
      let j = await this.$api.postJson('/dev/tableWiki/getDocument', {query: tableObj});
      this.busy.meta = false;
      if (j) {
        let colMap = {};

        this.doc = j.doc || {...tableObj, name: tableObj.table, desc: '', columns: []};
        this.doc.columns.forEach(e => colMap[e.name] = e);
        j.cols.forEach(e => { // mysql 에 column 사전정의가 있다면 사용
          let obj = colMap[e.COLUMN_NAME] = colMap[e.COLUMN_NAME] || {name: e.COLUMN_NAME};
          Object.assign(obj, e);
          // link 가 있다면 값을 찾아오며, 동일 링크가 어디어디에서 사용되는지 볼 수 있게 한다.
        });
        j.sample && Object.entries(j.sample).forEach(([k, v]) => {
          let obj = colMap[k] = colMap[k] || {name: k};
          obj.sample = typeof v === 'object' ? JSON.stringify(v) : v;
          obj.sample_type = v != null ? this.$utils.typeOf(v) : null;
        });

        // preset 이 있다면 반영한다.
        Object.entries(this.preset).forEach(([k, v]) => {
          let col = colMap[k];
          if (col) {
            col.type = col.type || v.type;
            col.desc = col.desc || v.desc;
            col.mask = col.mask || v.mask;
          }
        });

        // 이름에 따라 정렬
        this.columns = Object.values(colMap).sort((a, b) =>
          a.name[0] === '_' && b.name[0] !== '_' ? 1 :
            a.name[0] !== '_' && b.name[0] === '_' ? -1 :
              a.name.localeCompare(b.name));

        // link 를 불러온다.
        await this.loadLinks();

        this.$refs.ht.hotInstance.loadData(this.columns);
        this.currentField = '';
        this.toggle.json = false;
        this.sampleQuery = '';
      }
    },
    async loadSample(tableObj, where) {
      this.busy.meta = true;
      let j = await this.$api.postJson('/dev/tableWiki/getSample', {query: tableObj, where});
      this.busy.meta = false;
      if (j) {
        const colMap = {}, noSampleColMap = {};
        this.columns.forEach(e => noSampleColMap[e.name] = colMap[e.name] = e);
        j.sample && Object.entries(j.sample).forEach(([k, v]) => {
          let obj = colMap[k] = colMap[k] || {name: k};
          delete noSampleColMap[k];
          obj.sample = typeof v === 'object' ? JSON.stringify(v) : v;
          obj.sample_type = v != null ? this.$utils.typeOf(v) : null;
        });

        // 샘플 변경으로 인해 추가된 column 에 대해서도 preset 이 있다면 반영한다.
        Object.entries(this.preset).forEach(([k, v]) => {
          let col = colMap[k];
          if (col) {
            col.type = col.type || v.type;
            col.desc = col.desc || v.desc;
            col.mask = col.mask || v.mask;
          }
        });

        // 샘플이 없는 col 에 대해 샘플 삭제
        Object.values(noSampleColMap).forEach(v => {
          delete v.sample;
          delete v.sample_type;
        });

        this.$refs.ht.hotInstance.loadData(this.columns = Object.values(colMap).sort((a, b) =>
          a.name[0] === '_' && b.name[0] !== '_' ? 1 :
            a.name[0] !== '_' && b.name[0] === '_' ? -1 :
              a.name.localeCompare(b.name)));
        this.currentField = '';
        this.toggle.json = false;
      }
    },
    /**
     * column 설명에 link 가 있다면 불러오고, 값이 없을 때 덮어쓴다. 이후 저장시 값이 없게 저장되도록 해야 한다.
     */
    async loadLinks() {
      const {group, server, db, table} = this.doc;
      const links = this.columns.filter(e => e.link).map(e => {
        // fullLink : group, server, db, table, column 을 . 으로 묶은 것
        const linkChunks = e.link.split('.');
        const fullLink = e.fullLink = [group, server, db, table, null].slice(0, -linkChunks.length).concat(linkChunks);
        return {name: e.name, link: e.link, fullLink};
      });

      // 편집중에 링크가 삭제된 경우도 고려한다.
      this.columns.filter(e => !e.link && (e.link_desc || e.link_desc || e.link_mask)).forEach(e => {
        delete e.link_type;
        delete e.link_desc;
        delete e.link_mask;
      });

      let hasMoreLink = links.length > 0;
      let cnt = 0;
      const linkMap = {};

      // linkMap 에도 link 가 있다면 끝까지 추적하되, 타입이나 desc 는 가장 가까운 링크를 사용한다.
      this.busy.meta = true;
      while (hasMoreLink && cnt++ < 3) { // 서버 문제 등으로 j 가 없을 때를 대비 10 회 한정
        const j = await this.$api.postJson('/dev/tableWiki/getLinks', {links});
        if (j) {
          const linkStrs = [];
          Object.assign(linkMap, j.linkMap);
          Object.values(linkMap).filter(e => e).forEach(v => { // table wiki 가 없을 수 있어서 filter 가 필요하다
            // fullLink 생성 및 table 비교
            const {group, server, db, table} = v;
            v.columns.filter(e => e.link).forEach(e => {
              const linkChunks = e.link.split('.'); // [db, table, column] 등
              const fullLink = [group, server, db, table, null].slice(0, -linkChunks.length).concat(linkChunks);
              if (!linkMap[fullLink.slice(0, 4).join('|')]) {
                // 새로운 링크라면 다시 가져오기
                linkStrs.push(fullLink.slice(0, 4).join('|'));
              }
            });
          });

          if (linkStrs.length) {
            links.splice(0, links.length);
            linkStrs.set().forEach(e => {
              links.push({fullLink: e.split('|')});
            });
          } else {
            hasMoreLink = false;
          }
        } else {
          return;
        }
      }
      this.busy.meta = false;

      /**
       * group.server.db.table 에서 원본 링크가 걸려있을 때, 해당 원본링크를 recursive 하게 찾는다.
       * ex) a.b.c.d.col 에 cc.dd.col2 가 링크일 경우 -> a.b.cc.dd.col2 를 찾는다.
       *     findLink('cc.dd.col2', {group: a, server: b, db: c, table: d})
       *
       * @param {string} link       설명을 가져올 원본 링크
       * @param {string} group      현재의 그룹
       * @param {string} server     현재의 서버
       * @param {string} db         현재의 DB
       * @param {string} table      현재의 테이블
       * @param {number} linkCnt    link 연결 수, 순환참조 방지를 위해 최대 5 회로 제한한다.
       * @return {{type: string, desc: string, mask: string}}
       */
      function findLink(link, {group, server, db, table}, linkCnt = 0) {
        if (linkCnt >= 5) return {type: '', desc: '', mask: ''};

        let type;
        let desc;
        let mask;
        const linkChunks = link.split('.');
        const fullLink = [group, server, db, table, null].slice(0, -linkChunks.length).concat(linkChunks);
        const linkObj = linkMap[fullLink.slice(0, 4).join('|')];
        if (linkObj) {
          const linkCol = linkObj.columns.filter(c => c.name === fullLink[4])[0];
          if (linkCol) {
            type = linkCol.type;
            desc = linkCol.desc;
            mask = linkCol.mask;
            if (linkCol.link) { // linkCol.link 는 기준 테이블이 fullLink 의 테이블이다.
              const {type: t, desc: d, mask: m} = findLink(linkCol.link, {group: fullLink[0], server: fullLink[1], db: fullLink[2], table: fullLink[3]}, ++linkCnt);
              // today_pick 등의 컬럼은 mongodb 에서는 true, mysql 에서는 1 로 타입이 다르기에 가까운 링크의 타입을 우선시한다.
              type = type || t;
              desc = desc || d;
              mask = mask || m;
            }
          }
        }
        return {type, desc, mask};
      }

      this.columns.filter(e => e.link).forEach(e => {
        const [group, server, db, table] = e.fullLink;
        const {type, desc, mask} = findLink(e.link, {group, server, db, table});
        if (type) e.link_type = type;
        if (desc) e.link_desc = desc;
        if (mask) e.link_mask = mask;
      });

      // setTimeout(this.$forceUpdate, 1000);
      this.$refs.ht.hotInstance.loadData(this.columns);

      // 컬럼 필터 시도
      // const filtersPlugin = this.$refs.ht.hotInstance.getPlugin('filters');
      // if (filtersPlugin) filtersPlugin.addCondition(0, 'contains', ['s'])
      // new Controller(this, {
      //   selectedColumn: 0,
      //   addConditionsByValue: curry((values, column) => {
      //     arrayEach(values, value => filtersPlugin.addCondition(column, 'not_contains', [value]));
      //   }),
      //   filter: () => filtersPlugin.filter(),
      //   removeConditions: column => filtersPlugin.removeConditions(column)
      // });
    },
    async updateDb() {
      this.busy.doc = true;
      let {db, group, server, desc} = this.dbObj;
      let j = await this.$api.postJson('/dev/tableWiki/updateDb', {db, group, server, desc});
      this.busy.doc = false;
      if (j) {
        this.$alertTop('저장되었습니다.');
      }
    },
    async updateDocument() {
      let {group, server, db, table, name, links, desc} = this.doc;
      if (!group || !server || !db || !table) return; // afterChange 에 의해 빈 값이 trigger 됨
      let columns = this.columns.filter(e => !this.preset[e.name]).map(e => {
        let {name, type, desc, link, mask} = e;
        return {name, type, desc, link, mask};
      });
      this.busy.doc = true;
      let j = await this.$api.postJson('/dev/tableWiki/updateDocument', {group, server, db, table, name, links, desc, columns});
      this.busy.doc = false;
      if (j) {
        this.$alertTop('저장되었습니다.');
        await this.loadLinks(); // 링크도 갱신한다.
      }
    },
    copyLink(type, obj) {
      let res = this.$utils.copyToClipboard(`https://hub.balaan.io/#/dev/tableWiki?group=${obj.group}&server=${obj.server}&db=${obj.db}${type === 'table' ? `&table=${obj.table}` : ''}`);
      if (res) this.$alertTop(`링크가 복사되었습니다`);
    },
    toggleJson() {
      this.toggle.json = !this.toggle.json;
      if (this.toggle.json) {
        try {
          this.currentFieldJson = JSON.stringify(JSON.parse(this.currentField), null, 2);
        } catch (e) {
          this.currentFieldJson = this.currentField;
        }
      }
    },
    downXlsxModal(type, obj) {
      this.downXlsxParams = {type, obj};
      this.modal.down = true;
    },
    downXlsx() {
      if (this.busy.xlsx) return;
      this.busy.xlsx = true;
      this.frameCallback = () => { // 스크립트에서 에러가 날 때 이쪽 경로를 탄다.
        this.busy.xlsx = false;
      }
      const {type, obj} = this.downXlsxParams;
      const {group, server, db, table} = obj;
      this.$refs.json_data.value = JSON.stringify({obj: {group, server, db, table}, type, mergeSheet: this.mergeSheet});
      this.$refs.file_form.action = this.$api.getHost() + '/dev/tableWiki/downXlsx';
      this.$refs.file_form.submit();
    },
    frameLoaded() {
      if (this.frameCallback) {
        this.frameCallback();
        this.frameCallback = null;
      }
    },
    copyTable(doc) {
      this.copyTableSrc = doc;
      this.copyTableDst = '';
      this.modal.copyTable = true;
    },
    async copyTableCount() {
      if (this.copyTableSrc.group === 'mongo' && this.copyTableOptions.useQuery) {
        try {
          JSON.parse(this.copyTableOptions.query);
        } catch(e) {
          return alert('Query 를 JSON 형식으로 입력해주세요.');
        }
      }
      const {group, server, db, table} = this.copyTableSrc;
      const query = this.copyTableOptions.useQuery ? new Function('return ' + this.copyTableOptions.query)() : {};

      this.busy.copyTable = true;
      const j = await this.$api.postJson('/dev/tableWiki/copyTableCount', {
        group,
        src: `${server}.${db}.${table}`,
        dst: this.copyTableDst,
        ...this.copyTableOptions,
        query
      });
      this.busy.copyTable = false;
      if (j) {
        this.$utils.alert(j.count + ' 건의 데이터가 발견되었습니다');
      }
    },
    async copyTableStart() {
      if (!this.copyTableDst) return alert('복사될 위치를 지정해주세요.');
      if (this.copyTableSrc.group === 'mongo' && this.copyTableOptions.useQuery) {
        try {
          JSON.parse(this.copyTableOptions.query);
        } catch(e) {
          return alert('Query 를 JSON 형식으로 입력해주세요.');
        }
      }

      const {group, server, db, table} = this.copyTableSrc;
      this.busy.copyTable = true;
      const j = await this.$api.postJson('/dev/tableWiki/copyTable', {group, src: `${server}.${db}.${table}`, dst: this.copyTableDst, ...this.copyTableOptions});
      this.busy.copyTable = false;
      if (j) {
        this.$utils.alert('작업이 시작되었습니다');
      }
    },
    async showMaskingTables() {
      const j = await this.$api.getJson('/dev/tableWiki/getMaskingTables');
      if (j) {
        this.modal.masking = true;
        this.maskingTables = j.tables.join('\n');
      }
    },
    async maskTable(doc) {
      const {group, server, db, table} = doc;
      if (!confirm(`${table} 의 마스킹 처리를 시작하시겠습니까? 원복은 불가합니다.`)) return;

      const j = await this.$api.postJson('/dev/tableWiki/maskTable', {group, dst: `${server}.${db}.${table}`});
      if (j) {
        this.$utils.alert('작업이 시작되었습니다');
      }
    },

  },
}
</script>

<style>
@import '~handsontable/dist/handsontable.full.css';
</style>
